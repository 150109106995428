import axios from 'axios';
import { useUserStore } from '../store/userStore';

const API_URL = import.meta.env.VITE_API_URL;

export async function incrementViewCount(locationId: string): Promise<number> {
  try {
    const userState = useUserStore.getState();
    const headers: Record<string, string> = {};
    
    if (userState.isAuthenticated && userState.token) {
      headers['Authorization'] = `Bearer ${userState.token}`;
    }

    const response = await axios.post(
      `${API_URL}/locations/${locationId}/views`,
      {},
      { headers }
    );

    return response.data.viewCount || 0;
  } catch (error) {
    console.error('Failed to increment view count:', error);
    return 0;
  }
}

export async function getViewCount(locationId: string): Promise<number> {
  try {
    const response = await axios.get(`${API_URL}/locations/${locationId}/views`);
    return response.data.viewCount || 0;
  } catch (error) {
    console.error('Failed to get view count:', error);
    return 0;
  }
}