import axios from 'axios';
import { User } from '../types/user';

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:3000/api';

export async function createUser(wechatUserInfo: any): Promise<User> {
  try {
    const response = await axios.post(`${API_URL}/users`, {
      wechatId: wechatUserInfo.openid,
      nickname: wechatUserInfo.nickname,
      avatar: wechatUserInfo.headimgurl,
      city: wechatUserInfo.city,
      province: wechatUserInfo.province,
    });
    
    return response.data;
  } catch (error) {
    console.error('Failed to create user:', error);
    throw new Error('Failed to create user');
  }
}

export async function getUserByWechatId(wechatId: string): Promise<User | null> {
  try {
    const response = await axios.get(`${API_URL}/users/wechat/${wechatId}`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return null;
    }
    throw error;
  }
}