import { Location, FilterOption } from '../types/location';

export const regions: FilterOption[] = [
  { id: 'northeast', name: '东北' },
  { id: 'north', name: '华北' },
  { id: 'east', name: '华东' },
  { id: 'south', name: '华南' },
  { id: 'central', name: '华中' },
  { id: 'southwest', name: '西南' },
  { id: 'northwest', name: '西北' },
];

export const categories: FilterOption[] = [
  { id: '01', name: '山野' },
  { id: '02', name: '江河' },
  { id: '03', name: '滨海' },
  { id: '04', name: '古城' },
  { id: '05', name: '边塞' },
  { id: '06', name: '大漠' },
];

export const provinces: FilterOption[] = [
  { id: 'yunnan', name: '云南省' },
  { id: 'hubei', name: '湖北省' },
  { id: 'xizang', name: '西藏自治区' },
  { id: 'xinjiang', name: '新疆维吾尔自治区' },
  { id: 'jiangsu', name: '江苏省' },
  { id: 'zhejiang', name: '浙江省' },
  { id: 'shanxi', name: '山西省' },
  { id: 'gansu', name: '甘肃省' },
  { id: 'anhui', name: '安徽省' },
  { id: 'jilin', name: '吉林省' },
  { id: 'fujian', name: '福建省' },
  { id: 'shaanxi', name: '陕西省' },
  { id: 'guangxi', name: '广西壮族自治区' },
  { id: 'guizhou', name: '贵州省' },
  { id: 'sichuan', name: '四川省' },
  { id: 'hebei', name: '河北省' },
  { id: 'hunan', name: '湖南省' },
  { id: 'guangdong', name: '广东省' },
  { id: 'jiangxi', name: '江西省' },
  { id: 'neimenggu', name: '内蒙古自治区' },
  { id: 'heilongjiang', name: '黑龙江省' },
];

export const locations: Location[] = [
  {
    id: 'lvchun',
    fullName: '绿春县',
    shortName: '绿春',
    province: {
      id: 'yunnan',
      fullName: '云南省',
      shortName: '云南'
    },
    region: 'southwest',
    category: {
      id: '01',
      name: '山野'
    },
    imageUrl: 'https://images.unsplash.com/photo-1501555088652-021faa106b9b?auto=format&fit=crop&q=80',
    description: '哈尼梯田的故乡，云雾缭绕的红河之滨',
    rating: 4.8,
    viewCount: 12453
  },
  {
    id: 'zigui',
    fullName: '秭归县',
    shortName: '秭归',
    province: {
      id: 'hubei',
      fullName: '湖北省',
      shortName: '湖北'
    },
    region: 'central',
    category: {
      id: '02',
      name: '江河'
    },
    imageUrl: 'https://images.unsplash.com/photo-1508193638397-1c4234db14d8?auto=format&fit=crop&q=80',
    description: '三峡库区明珠，山水相依，人文荟萃的生态旅游胜地',
    rating: 4.7,
    viewCount: 15678
  },
  {
    id: 'motuo',
    fullName: '墨脱县',
    shortName: '墨脱',
    province: {
      id: 'xizang',
      fullName: '西藏自治区',
      shortName: '西藏'
    },
    region: 'southwest',
    category: {
      id: '01',
      name: '山野'
    },
    imageUrl: 'https://images.unsplash.com/photo-1544735716-392fe2489ffa?auto=format&fit=crop&q=80',
    description: '西藏最后一个通公路的县城，神秘而原始的香格里拉',
    rating: 4.9,
    viewCount: 23456
  },
  {
    id: 'burqin',
    fullName: '布尔津县',
    shortName: '布尔津',
    province: {
      id: 'xinjiang',
      fullName: '新疆维吾尔自治区',
      shortName: '新疆'
    },
    region: 'northwest',
    category: {
      id: '05',
      name: '边塞'
    },
    imageUrl: 'https://images.unsplash.com/photo-1542314831-068cd1dbfeeb?auto=format&fit=crop&q=80',
    description: '喀纳斯湖畔的边塞明珠，冰雪与森林交织的北国风光',
    rating: 4.7,
    viewCount: 18234
  },
  {
    id: 'xuyi',
    fullName: '盱眙县',
    shortName: '盱眙',
    province: {
      id: 'jiangsu',
      fullName: '江苏省',
      shortName: '江苏'
    },
    region: 'east',
    category: {
      id: '02',
      name: '江河'
    },
    imageUrl: 'https://images.unsplash.com/photo-1513415277900-a62401e19be4?auto=format&fit=crop&q=80',
    description: '江淮明珠，龙虾美食之都，千年文化底蕴的江南水乡',
    rating: 4.6,
    viewCount: 9876
  },
  {
    id: 'zhuji',
    fullName: '诸暨市',
    shortName: '诸暨',
    province: {
      id: 'zhejiang',
      fullName: '浙江省',
      shortName: '浙江'
    },
    region: 'east',
    category: {
      id: '02',
      name: '江河'
    },
    imageUrl: 'https://images.unsplash.com/photo-1505993597083-3bd19fb75e57?auto=format&fit=crop&q=80',
    description: '越国古都，珍珠之乡，江南水乡与现代产业交相辉映',
    rating: 4.5,
    viewCount: 8765
  },
  {
    id: 'xiaoyi',
    fullName: '孝义市',
    shortName: '孝义',
    province: {
      id: 'shanxi',
      fullName: '山西省',
      shortName: '山西'
    },
    region: 'north',
    category: {
      id: '01',
      name: '山野'
    },
    imageUrl: 'https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?auto=format&fit=crop&q=80',
    description: '吕梁山下的能源重镇，传统与现代交融的工业新城',
    rating: 4.4,
    viewCount: 6543
  },
  {
    id: 'yumen',
    fullName: '玉门市',
    shortName: '玉门',
    province: {
      id: 'gansu',
      fullName: '甘肃省',
      shortName: '甘肃'
    },
    region: 'northwest',
    category: {
      id: '06',
      name: '大漠'
    },
    imageUrl: 'https://images.unsplash.com/photo-1682686580391-615b1e32be1d?auto=format&fit=crop&q=80',
    description: '丝绸之路上的重镇，戈壁油城的历史见证',
    rating: 4.6,
    viewCount: 7654
  },
  {
    id: 'tongcheng',
    fullName: '桐城市',
    shortName: '桐城',
    province: {
      id: 'anhui',
      fullName: '安徽省',
      shortName: '安徽'
    },
    region: 'east',
    category: {
      id: '02',
      name: '江河'
    },
    imageUrl: 'https://images.unsplash.com/photo-1599707367072-cd6ada2bc375?auto=format&fit=crop&q=80',
    description: '桐城派发源地，皖西南的文化重镇',
    rating: 4.5,
    viewCount: 5432
  },
  {
    id: 'yanji',
    fullName: '延吉市',
    shortName: '延吉',
    province: {
      id: 'jilin',
      fullName: '吉林省',
      shortName: '吉林'
    },
    region: 'northeast',
    category: {
      id: '05',
      name: '边塞'
    },
    imageUrl: 'https://images.unsplash.com/photo-1547981609-4b6bfe67ca0b?auto=format&fit=crop&q=80',
    description: '延边朝鲜族自治州首府，中朝边境文化交融的现代化城市',
    rating: 4.7,
    viewCount: 13456
  },
  {
    id: 'shishi',
    fullName: '石狮市',
    shortName: '石狮',
    province: {
      id: 'fujian',
      fullName: '福建省',
      shortName: '福建'
    },
    region: 'east',
    category: {
      id: '03',
      name: '滨海'
    },
    imageUrl: 'https://images.unsplash.com/photo-1507525428034-b723cf961d3e?auto=format&fit=crop&q=80',
    description: '闽南侨乡，海丝文化与现代时尚交相辉映',
    rating: 4.6,
    viewCount: 11234
  },
  {
    id: 'hancheng',
    fullName: '韩城市',
    shortName: '韩城',
    province: {
      id: 'shaanxi',
      fullName: '陕西省',
      shortName: '陕西'
    },
    region: 'northwest',
    category: {
      id: '04',
      name: '古城'
    },
    imageUrl: 'https://images.unsplash.com/photo-1493962853295-0fd70327578a?auto=format&fit=crop&q=80',
    description: '黄河古城，千年文化与现代魅力交相辉映',
    rating: 4.7,
    viewCount: 14567
  },
  {
    id: 'beiliu',
    fullName: '北流市',
    shortName: '北流',
    province: {
      id: 'guangxi',
      fullName: '广西壮族自治区',
      shortName: '广西'
    },
    region: 'south',
    category: {
      id: '01',
      name: '山野'
    },
    imageUrl: 'https://images.unsplash.com/photo-1501785888041-af3ef285b470?auto=format&fit=crop&q=80',
    description: '岭南陶都，山水秀丽的文化古城',
    rating: 4.5,
    viewCount: 8765
  },
  {
    id: 'liuzhi',
    fullName: '六枝特区',
    shortName: '六枝',
    province: {
      id: 'guizhou',
      fullName: '贵州省',
      shortName: '贵州'
    },
    region: 'southwest',
    category: {
      id: '02',
      name: '江河'
    },
    imageUrl: 'https://images.unsplash.com/photo-1502014822147-1aedfb0676e0?auto=format&fit=crop&q=80',
    description: '多彩贵州的生态明珠，喀斯特地貌与少数民族文化的交融',
    rating: 4.8,
    viewCount: 16789
  },
  {
    id: 'kangding',
    fullName: '康定市',
    shortName: '康定',
    province: {
      id: 'sichuan',
      fullName: '四川省',
      shortName: '四川'
    },
    region: 'southwest',
    category: {
      id: '02',
      name: '江河'
    },
    imageUrl: 'https://images.unsplash.com/photo-1508193638397-1c4234db14d8?auto=format&fit=crop&q=80',
    description: '康巴高原的明珠，藏羌文化与自然风光的完美融合',
    rating: 4.9,
    viewCount: 19876
  },
  {
    id: 'huailai',
    fullName: '怀来县',
    shortName: '怀来',
    province: {
      id: 'hebei',
      fullName: '河北省',
      shortName: '河北'
    },
    region: 'north',
    category: {
      id: '01',
      name: '山野'
    },
    imageUrl: 'https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?auto=format&fit=crop&q=80',
    description: '长城脚下的葡萄之乡，京北生态休闲度假胜地',
    rating: 4.6,
    viewCount: 9876
  },
  {
    id: 'huayuan',
    fullName: '花垣县',
    shortName: '花垣',
    province: {
      id: 'hunan',
      fullName: '湖南省',
      shortName: '湖南'
    },
    region: 'central',
    category: {
      id: '02',
      name: '江河'
    },
    imageUrl: 'https://images.unsplash.com/photo-1501785888041-af3ef285b470?auto=format&fit=crop&q=80',
    description: '湘西腹地的自然奇观，层叠梯田与民族文化交相辉映',
    rating: 4.8,
    viewCount: 15678
  },
  {
    id: 'xuwen',
    fullName: '徐闻县',
    shortName: '徐闻',
    province: {
      id: 'guangdong',
      fullName: '广东省',
      shortName: '广东'
    },
    region: 'south',
    category: {
      id: '03',
      name: '滨海'
    },
    imageUrl: 'https://images.unsplash.com/photo-1518509562904-e7ef99cdcc86?auto=format&fit=crop&q=80',
    description: '粤港澳大湾区的海上门户，热带水果之乡',
    rating: 4.7,
    viewCount: 12345
  },
  {
    id: 'longnan',
    fullName: '龙南市',
    shortName: '龙南',
    province: {
      id: 'jiangxi',
      fullName: '江西省',
      shortName: '江西'
    },
    region: 'east',
    category: {
      id: '01',
      name: '山野'
    },
    imageUrl: 'https://images.unsplash.com/photo-1501785888041-af3ef285b470?auto=format&fit=crop&q=80',
    description: '赣粤门户，客家文化与山水生态的完美结合',
    rating: 4.5,
    viewCount: 8765
  },
  {
    id: 'elunchun',
    fullName: '鄂伦春自治旗',
    shortName: '鄂伦春',
    province: {
      id: 'neimenggu',
      fullName: '内蒙古自治区',
      shortName: '内蒙古'
    },
    region: 'north',
    category: {
      id: '01',
      name: '山野'
    },
    imageUrl: 'https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?auto=format&fit=crop&q=80',
    description: '大兴安岭腹地，鄂伦春族传统文化与森林生态的完美融合',
    rating: 4.8,
    viewCount: 7654
  },
  {
    id: 'fuyuan',
    fullName: '抚远市',
    shortName: '抚远',
    province: {
      id: 'heilongjiang',
      fullName: '黑龙江省',
      shortName: '黑龙江'
    },
    region: 'northeast',
    category: {
      id: '05',
      name: '边塞'
    },
    imageUrl: 'https://images.unsplash.com/photo-1547981609-4b6bfe67ca0b?auto=format&fit=crop&q=80',
    description: '中国最东端的城市，黑龙江与乌苏里江交汇处的明珠',
    rating: 4.7,
    viewCount: 11234
  }
];