import React, { useState, useEffect } from 'react';
import { MapPin, Star, Eye } from 'lucide-react';
import { Location } from '../types/location';
import { incrementViewCount, getViewCount } from '../services/viewCountService';

interface LocationCardProps {
  location: Location;
}

export function LocationCard({ location }: LocationCardProps) {
  const [viewCount, setViewCount] = useState(location.viewCount || 0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadViewCount = async () => {
      try {
        const count = await getViewCount(location.id);
        setViewCount(count);
      } catch (error) {
        console.error('Failed to load view count:', error);
      }
    };

    loadViewCount();
  }, [location.id]);

  const handleViewDetails = async () => {
    if (isLoading) return;
    
    setIsLoading(true);
    try {
      const newViewCount = await incrementViewCount(location.id);
      setViewCount(newViewCount);
    } catch (error) {
      console.error('Failed to update view count:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
      <div className="relative h-48">
        <img 
          src={location.imageUrl} 
          alt={location.fullName} 
          className="w-full h-full object-cover"
        />
        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-4">
          <h3 className="text-white text-xl font-semibold">
            {location.fullName}
            <span className="text-sm ml-2 opacity-75">
              ({location.province.shortName})
            </span>
          </h3>
          <div className="flex items-center mt-1">
            <Star className="w-4 h-4 text-yellow-400 fill-current" />
            <span className="text-white text-sm ml-1">{location.rating.toFixed(1)}</span>
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="flex items-center space-x-2 mb-2">
          <span className="px-2 py-1 bg-gray-100 rounded-full text-xs text-gray-600">
            {location.category.name}
          </span>
        </div>
        <p className="text-gray-600 text-sm">{location.description}</p>
        <div className="mt-4 flex items-center justify-between">
          <button
            onClick={handleViewDetails}
            disabled={isLoading}
            className="flex items-center text-blue-600 hover:text-blue-700 transition-colors disabled:opacity-50"
          >
            <MapPin className="w-4 h-4 mr-1" />
            <span className="text-sm">查看详情</span>
          </button>
          <div className="flex items-center text-gray-500">
            <Eye className="w-4 h-4 mr-1" />
            <span className="text-sm">{viewCount.toLocaleString()}</span>
          </div>
        </div>
      </div>
    </div>
  );
}