import React, { useState } from 'react';
import { Coffee, Users } from 'lucide-react';
import wechatQR from '@/assets/wechat-qr.jpg';
import coffeeQR from '@/assets/coffee-qr.jpg';

export function SocialLinks() {
  const [showWeChatQR, setShowWeChatQR] = useState(false);
  const [showCoffeeQR, setShowCoffeeQR] = useState(false);

  return (
    <div className="fixed bottom-8 left-0 right-0 z-50">
      <div className="max-w-xl mx-auto px-4 flex justify-center space-x-8">
        <div className="relative group">
          <button
            className="flex items-center text-gray-600 hover:text-gray-900 transition-colors"
            onMouseEnter={() => setShowWeChatQR(true)}
            onMouseLeave={() => setShowWeChatQR(false)}
          >
            <Users className="w-5 h-5 mr-2" />
            <span>交个朋友</span>
          </button>
          
          {showWeChatQR && (
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-4">
              <div className="bg-white rounded-lg shadow-xl p-6">
                <div className="w-[280px] aspect-square relative">
                  <img 
                    src={wechatQR}
                    alt="扫一扫上面的二维码图案，加我为朋友" 
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
                <p className="text-sm text-gray-600 mt-4 text-center whitespace-nowrap">
                  扫一扫上面的二维码图案，加我为朋友
                </p>
              </div>
              <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-4 h-4 bg-white rotate-45"></div>
            </div>
          )}
        </div>

        <div className="relative group">
          <button
            className="flex items-center text-gray-600 hover:text-gray-900 transition-colors"
            onMouseEnter={() => setShowCoffeeQR(true)}
            onMouseLeave={() => setShowCoffeeQR(false)}
          >
            <Coffee className="w-5 h-5 mr-2" />
            <span>请喝咖啡</span>
          </button>
          
          {showCoffeeQR && (
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-4">
              <div className="bg-white rounded-lg shadow-xl p-6">
                <div className="w-[280px] aspect-square relative">
                  <img 
                    src={coffeeQR}
                    alt="奔县.net 的赞赏码" 
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
                <p className="text-sm text-gray-600 mt-4 text-center whitespace-nowrap">
                  感谢您的支持！
                </p>
              </div>
              <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-4 h-4 bg-white rotate-45"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}