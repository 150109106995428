import React, { useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useUserStore } from '../store/userStore';
import { getWeChatLoginUrl } from '../services/wechatService';

interface WeChatLoginProps {
  onSuccess: () => void;
}

export function WeChatLogin({ onSuccess }: WeChatLoginProps) {
  const [qrCodeUrl, setQrCodeUrl] = React.useState('');
  const [error, setError] = React.useState('');

  useEffect(() => {
    const initializeQRCode = async () => {
      try {
        const url = await getWeChatLoginUrl();
        setQrCodeUrl(url);
      } catch (err) {
        setError('获取二维码失败，请稍后重试');
        console.error('Failed to get WeChat QR code:', err);
      }
    };

    initializeQRCode();
  }, []);

  if (error) {
    return (
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="bg-white p-8 rounded-lg shadow-lg text-center">
      <h2 className="text-xl font-semibold mb-4">微信扫码登录</h2>
      <div className="inline-block p-4 bg-green-50 rounded-lg">
        <QRCodeSVG value={qrCodeUrl} size={200} />
      </div>
      <p className="mt-4 text-gray-600">请使用微信扫描二维码登录</p>
    </div>
  );
}