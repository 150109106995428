import React, { useMemo } from 'react';
import { Filter, X } from 'lucide-react';
import { FilterOption } from '../types/location';
import { locations } from '../data/locations';

interface ExploreFiltersProps {
  regions: FilterOption[];
  provinces: FilterOption[];
  categories: FilterOption[];
  selectedRegion: string;
  selectedProvince: string;
  selectedCategory: string;
  minRating: number;
  onRegionChange: (region: string) => void;
  onProvinceChange: (province: string) => void;
  onCategoryChange: (category: string) => void;
  onRatingChange: (rating: number) => void;
  onClearFilters: () => void;
}

export function ExploreFilters({
  regions,
  provinces,
  categories,
  selectedRegion,
  selectedProvince,
  selectedCategory,
  minRating,
  onRegionChange,
  onProvinceChange,
  onCategoryChange,
  onRatingChange,
  onClearFilters,
}: ExploreFiltersProps) {
  // Filter provinces based on selected region
  const availableProvinces = useMemo(() => {
    if (!selectedRegion) return provinces;
    const provinceIds = new Set(
      locations
        .filter(loc => loc.region === selectedRegion)
        .map(loc => loc.province.id)
    );
    return provinces.filter(province => provinceIds.has(province.id));
  }, [selectedRegion, provinces]);

  // Filter categories based on selected region and province
  const availableCategories = useMemo(() => {
    let filteredLocations = locations;
    if (selectedRegion) {
      filteredLocations = filteredLocations.filter(loc => loc.region === selectedRegion);
    }
    if (selectedProvince) {
      filteredLocations = filteredLocations.filter(loc => loc.province.id === selectedProvince);
    }
    const categoryIds = new Set(filteredLocations.map(loc => loc.category.id));
    return categories.filter(category => categoryIds.has(category.id));
  }, [selectedRegion, selectedProvince, categories]);

  // Handle region change
  const handleRegionChange = (region: string) => {
    onRegionChange(region);
    onProvinceChange(''); // Reset province when region changes
    onCategoryChange(''); // Reset category when region changes
  };

  // Handle province change
  const handleProvinceChange = (province: string) => {
    onProvinceChange(province);
    onCategoryChange(''); // Reset category when province changes
  };

  return (
    <div className="bg-white shadow-sm rounded-lg p-4 mb-6">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <Filter className="w-5 h-5 text-gray-600" />
          <h2 className="text-lg font-medium text-gray-800">筛选</h2>
        </div>
        <button
          onClick={onClearFilters}
          className="flex items-center text-sm text-gray-600 hover:text-gray-900"
        >
          <X className="w-4 h-4 mr-1" />
          清除筛选
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            地区
          </label>
          <select
            value={selectedRegion}
            onChange={(e) => handleRegionChange(e.target.value)}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          >
            <option value="">全部地区</option>
            {regions.map((region) => (
              <option key={region.id} value={region.id}>
                {region.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            省份
          </label>
          <select
            value={selectedProvince}
            onChange={(e) => handleProvinceChange(e.target.value)}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            disabled={!selectedRegion}
          >
            <option value="">全部省份</option>
            {availableProvinces.map((province) => (
              <option key={province.id} value={province.id}>
                {province.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            类别
          </label>
          <select
            value={selectedCategory}
            onChange={(e) => onCategoryChange(e.target.value)}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            disabled={!selectedRegion || !selectedProvince}
          >
            <option value="">全部类别</option>
            {availableCategories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            最低评分
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="range"
              min="0"
              max="5"
              step="0.5"
              value={minRating}
              onChange={(e) => onRatingChange(parseFloat(e.target.value))}
              className="w-full"
            />
            <span className="text-sm text-gray-600 min-w-[2.5rem]">
              {minRating}星
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}