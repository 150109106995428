import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUserStore } from '../store/userStore';
import { handleWeChatCallback } from '../services/wechatService';
import { createUser, getUserByWechatId } from '../services/userService';

export function WeChatCallback() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const setUser = useUserStore((state) => state.setUser);
  const [error, setError] = React.useState('');

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const code = searchParams.get('code');
        const state = searchParams.get('state');

        if (!code || !state) {
          throw new Error('Missing required parameters');
        }

        // Get user info from WeChat
        const wechatUserInfo = await handleWeChatCallback(code, state);
        
        // Check if user exists
        let user = await getUserByWechatId(wechatUserInfo.openid);
        
        // Create new user if doesn't exist
        if (!user) {
          user = await createUser(wechatUserInfo);
        }
        
        // Update global state
        setUser(user);
        
        // Redirect to home page
        navigate('/', { replace: true });
      } catch (err) {
        console.error('WeChat login failed:', err);
        setError('登录失败，请重试');
      }
    };

    handleCallback();
  }, [searchParams, setUser, navigate]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
          <p className="text-red-500">{error}</p>
          <button
            onClick={() => navigate('/')}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            返回首页
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="text-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
        <p className="mt-4 text-gray-600">正在处理登录...</p>
      </div>
    </div>
  );
}