import axios from 'axios';
import { WECHAT_CONFIG } from '../config/wechat';

interface WeChatAccessToken {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  openid: string;
  scope: string;
}

interface WeChatUserInfo {
  openid: string;
  nickname: string;
  sex: number;
  province: string;
  city: string;
  country: string;
  headimgurl: string;
  privilege: string[];
  unionid: string;
}

export async function getWeChatLoginUrl(): Promise<string> {
  const { appId, redirectUri, scope } = WECHAT_CONFIG;
  const state = Math.random().toString(36).substring(7);
  
  // Store state in sessionStorage for validation
  sessionStorage.setItem('wechat_state', state);
  
  const encodedRedirectUri = encodeURIComponent(redirectUri);
  return `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${encodedRedirectUri}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
}

export async function handleWeChatCallback(code: string, state: string): Promise<WeChatUserInfo> {
  // Validate state to prevent CSRF
  const savedState = sessionStorage.getItem('wechat_state');
  if (state !== savedState) {
    throw new Error('Invalid state parameter');
  }
  
  // Exchange code for access token
  const tokenResponse = await axios.get<WeChatAccessToken>(
    `https://api.weixin.qq.com/sns/oauth2/access_token?appid=${WECHAT_CONFIG.appId}&secret=${WECHAT_CONFIG.appSecret}&code=${code}&grant_type=authorization_code`
  );
  
  // Get user info using access token
  const userInfoResponse = await axios.get<WeChatUserInfo>(
    `https://api.weixin.qq.com/sns/userinfo?access_token=${tokenResponse.data.access_token}&openid=${tokenResponse.data.openid}&lang=zh_CN`
  );
  
  return userInfoResponse.data;
}