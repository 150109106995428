import React, { useState } from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { LocationCard } from '../components/LocationCard';
import { CategoryList } from '../components/CategoryList';

const categories = {
  '江河': [
    {
      title: '湖南花垣',
      imageUrl: 'https://images.unsplash.com/photo-1501785888041-af3ef285b470?auto=format&fit=crop&q=80',
      description: '湘西腹地的自然奇观，层叠梯田与民族文化交相辉映'
    },
    {
      title: '湖北秭归',
      imageUrl: 'https://images.unsplash.com/photo-1508193638397-1c4234db14d8?auto=format&fit=crop&q=80',
      description: '三峡库区明珠，山水相依，人文荟萃的生态旅游胜地'
    },
    {
      title: '陕西韩城',
      imageUrl: 'https://images.unsplash.com/photo-1493962853295-0fd70327578a?auto=format&fit=crop&q=80',
      description: '黄河古城，千年文化与现代魅力交相辉映'
    }
  ],
  '滨海': [
    {
      title: '福建石狮',
      imageUrl: 'https://images.unsplash.com/photo-1507525428034-b723cf961d3e?auto=format&fit=crop&q=80',
      description: '闽南侨乡，海丝文化与现代时尚交相辉映'
    },
    {
      title: '广东徐闻',
      imageUrl: 'https://images.unsplash.com/photo-1518509562904-e7ef99cdcc86?auto=format&fit=crop&q=80',
      description: '粤港澳大湾区的海上门户，热带水果之乡'
    },
    {
      title: '海上日出',
      imageUrl: 'https://images.unsplash.com/photo-1586882829491-b81178aa622e?auto=format&fit=crop&q=80',
      description: '在这里欣赏最美的海上日出，开启新的一天'
    }
  ],
  '山野': [
    {
      title: '云南绿春',
      imageUrl: 'https://images.unsplash.com/photo-1501555088652-021faa106b9b?auto=format&fit=crop&q=80',
      description: '哈尼梯田的故乡，云雾缭绕的红河之滨'
    },
    {
      title: '西藏墨脱',
      imageUrl: 'https://images.unsplash.com/photo-1544735716-392fe2489ffa?auto=format&fit=crop&q=80',
      description: '西藏最后一个通公路的县城，神秘而原始的香格里拉'
    },
    {
      title: '贵州六枝',
      imageUrl: 'https://images.unsplash.com/photo-1502014822147-1aedfb0676e0?auto=format&fit=crop&q=80',
      description: '多彩贵州的生态明珠，喀斯特地貌与少数民族文化的交融'
    }
  ]
};

export function Categories() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 3;

  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      <header className="bg-white shadow-sm">
        <div className="max-w-5xl mx-auto px-4 py-4">
          <div className="flex items-center">
            <button 
              onClick={() => navigate('/explore')}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <ArrowLeft className="w-6 h-6 text-gray-600" />
            </button>
            <h1 className="ml-4 text-2xl font-bold text-gray-800">奔县</h1>
          </div>
        </div>
      </header>

      <main className="max-w-5xl mx-auto px-4 py-8">
        {Object.entries(categories).map(([category, locations]) => (
          <section key={category} className="mb-12">
            <h2 className="text-xl font-semibold text-gray-800 mb-6">{category}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {locations.map((location, index) => (
                <LocationCard key={index} {...location} />
              ))}
            </div>
          </section>
        ))}
      </main>

      <CategoryList 
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
}