import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface CategoryListProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export function CategoryList({ currentPage, totalPages, onPageChange }: CategoryListProps) {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4">
      <div className="flex justify-between items-center max-w-5xl mx-auto">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage <= 1}
          className={`flex items-center ${
            currentPage <= 1 ? 'text-gray-300' : 'text-gray-600 hover:text-gray-900'
          }`}
        >
          <ChevronLeft className="w-5 h-5" />
          <span>上一页</span>
        </button>
        
        <span className="text-gray-600">
          第 {currentPage}/{totalPages} 页
        </span>
        
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage >= totalPages}
          className={`flex items-center ${
            currentPage >= totalPages ? 'text-gray-300' : 'text-gray-600 hover:text-gray-900'
          }`}
        >
          <span>下一页</span>
          <ChevronRight className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
}