import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { User, UserState } from '../types/user';

const initialState: Omit<UserState, 'setUser' | 'clearUser'> = {
  id: '',
  wechatId: '',
  createdAt: '',
  lastLoginAt: '',
  isAuthenticated: false,
};

export const useUserStore = create<UserState>()(
  persist(
    (set) => ({
      ...initialState,
      setUser: (user: User) => set({
        ...user,
        isAuthenticated: true,
        lastLoginAt: new Date().toISOString(),
      }),
      clearUser: () => set(initialState),
    }),
    {
      name: 'user-storage',
    }
  )
);